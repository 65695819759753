import React from 'react'
import { Four, One, Row, Three, Two } from '../components/Grid'
import SEO from '../components/SEO'
import { H1, H2, H4, SmallText } from '../components/Style'
import Button from '../components/Button'
import Layout from '../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/hub",
      "name": "Community Hub"
    }
  }]
}`

export default class extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          path="/hub"
          title="Community Hub – 500Tech"
          description="A place for people in the front-end industry"
          ld={breadcrumbs}
        />

        <Row>
          <Four>
            <H1>Community hub</H1>
          </Four>
        </Row>

        <Row>
          <Three>
            Community hub is a closed group for front-end engineers.
          </Three>
          <One>
            <SmallText>
              <H4>Participation rules</H4>
              <p>
                A public LinkedIn account showing JavaScript experience is
                required. Recruiters and business developers are not allowed.
                Members agree to receive our community emails.
              </p>
            </SmallText>

            <Button>Connect to hub</Button>
          </One>
        </Row>

        <Row>
          <Two>
            <H2>Workshops and master classes</H2>
            Learn front-end basics and advanced topics in lecture-style master
            classes and hands-on workshops available exclusively for hub
            members.
          </Two>
          <One>
            <H2>Perks</H2>
            Enjoy goodies and discounts for products, services, workshops, and
            conferences for front-end engineers.
          </One>
          <One>
            <H2>JS Pro</H2>
            An exclusive group of community hub members with over three years of
            JavaScript experience with special advanced events and perks.
          </One>
        </Row>
      </Layout>
    )
  }
}
